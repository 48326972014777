<template>
  <div class="profile">
    <div class="row">
      <div class="col-md-12">
        <div class="mx-4 my-4">
          <!-- <h1>Edit Profile</h1> -->
          <div class="w-25"></div>
          <div class="m-4">
            <div v-show="isPreview" class="profile__image-container">
              <img
                ref="cropImage"
                class="profile__image"
                id="crop__image"
                :src="previewUrl"
                alt=""
              />
            </div>
            <div v-if="isPreview === false" class="profile__image-container">
              <img :src="user.profileImage.Location" class="profile__image" />
            </div>
            <button v-show="isPreview" @click="cropImageEvent">
              Crop Image
            </button>
          </div>
          <form @submit.prevent="updateProfile">
            <div class="row gx-5">
              <div class="mb-3">
                <label for="profile_pic" class="form-label"
                  >Change profile pic</label
                >
                <input
                  class="form-control form-control-sm"
                  @change="updatePreview"
                  ref="profile_pic"
                  id="profile_pic"
                  type="file"
                />
              </div>
              <div class="col">
                <label for="firstName" class="form-label">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="firstName"
                  id="firstName"
                  aria-describedby="textHelp"
                />
                <div id="textHelp" class="form-text">
                  We'll never share your personal details with anyone else.
                </div>
              </div>
              <div class="col mb-3">
                <label for="lastName" class="form-label">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="lastName"
                  id="lastName"
                  aria-describedby="textHelp"
                />
                <div id="textHelp" class="form-text">
                  We'll never share your personal details with anyone else.
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="exampleInputEmail1" class="form-label"
                  >Email address</label
                >
                <input
                  type="email"
                  class="form-control"
                  v-model="email"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
                <div id="emailHelp" class="form-text">
                  We'll never share your email with anyone else.
                </div>
              </div>
              <div class="col mb-3">
                <label for="exampleInputPassword1" class="form-label"
                  >Phone</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="phone"
                  id="exampleInputPassword1"
                />
              </div>
            </div>

            <div class="mt-3">
              <div class="col mb-3">
                <label for="exampleInputPassword1" class="form-label"
                  >Eddie Key
                  <a target="_blank" href="https://beta.openai.com/login"
                    >(Open AI Key)</a
                  ></label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="apiKey"
                  id="exampleInputPassword1"
                />
              </div>
            </div>

            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label"
                >Address</label
              >
              <input
                type="text"
                class="form-control"
                v-model="address"
                id="exampleInputPassword1"
              />
            </div>

            <button type="submit" class="ed-btn" :disabled="isSubmitLoading">
              <span v-if="!isSubmitLoading">Submit</span>
              <span
                v-else
                class="spinner-border spinner-border-sm ml-2"
                :style="{ opacity: isSubmitLoading ? 1 : 0 }"
              ></span>
            </button>

            <!-- <div>
              <hr />
              <div class="mt-3">
                <div class="col mb-3">
                  <label for="currentPassword" class="form-label"
                    >Current Password</label
                  >
                  <input
                    type="password"
                    class="form-control"
                    v-model="currentPassword"
                    id="currentPassword"
                    aria-describedby="currentPasswordHelp"
                  />
                  <div
                    v-if="shouldShowCPasswordInput"
                    class="auth__notifier"
                    :class="{ shake: !$v.currentPassword.error }"
                  >
                    >Password must be at least 8 characters long.
                  </div>
                </div>
              </div>

              <div class="mt-3">
                <div class="col mb-3">
                  <label for="newPassword" class="form-label"
                    >New Password</label
                  >
                  <input
                    type="password"
                    class="form-control"
                    v-model="newPassword"
                    id="newPassword"
                    aria-describedby="newPasswordHelp"
                  />
                  <div
                    v-if="shouldShowNPasswordInput"
                    class="auth__notifier"
                    :class="{ shake: !$v.newPassword.error }"
                  >
                    >Password must be at least 8 characters long.
                  </div>
                </div>
              </div>

              <div class="mt-3">
                <div class="col mb-3">
                  <label for="confirmNewPassword" class="form-label"
                    >Confirm New Password</label
                  >
                  <input
                    type="password"
                    class="form-control"
                    v-model="confirmPassword"
                    id="confirmNewPassword"
                    aria-describedby="confirmNewPasswordHelp"
                  />
                  <div
                    v-if="shouldShowCNPasswordInput"
                    class="auth__notifier"
                    :class="{ shake: !$v.confirmPassword.error }"
                  >
                    >Should be same as Password.
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <div class="col mb-3">
                  <button
                    @click="handleResetPassword"
                    class="ed-btn"
                    :disabled="isLoading"
                  >
                    <span v-if="!isLoading">Reset Password</span>
                    <span
                      v-else
                      class="spinner-border spinner-border-sm ml-2"
                      :style="{ opacity: isLoading ? 1 : 0 }"
                    ></span>
                  </button>
                </div>
              </div>
            </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      previewUrl: '',
      apiKey: null,
      cropper: null,
      isPreview: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      isLoading: false,
      isSubmitLoading: false,
    };
  },

  // validations: {
  //   currentPassword: {
  //     required,
  //     minLength: minLength(8),
  //   },
  //   newPassword: {
  //     required,
  //     minLength: minLength(8),
  //   },
  //   confirmPassword: {
  //     required,
  //     minLength: minLength(8),
  //     sameAsPassword: sameAs('newPassword'),
  //   },
  // },

  computed: {
    ...mapGetters({
      user: 'auth/authUser',
    }),
    // shouldShowCPasswordInput() {
    //   return (
    //     this.$v.currentPassword.required && !this.$v.currentPassword.minLength
    //   );
    // },
    // shouldShowNPasswordInput() {
    //   return this.$v.newPassword.required && !this.$v.newPassword.minLength;
    // },
    // shouldShowCNPasswordInput() {
    //   return (
    //     this.$v.confirmPassword.required
    //     && !this.$v.confirmPassword.sameAsPassword
    //   );
    // },
  },
  mounted() {
    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;
    this.phone = this.user.phone;
    this.email = this.user.email;
    this.address = this.user.address;
    this.apiKey = this.user?.apiKey || '';
    this.cropper = new Cropper(this.$refs.cropImage, {
      aspectRatio: 1,
      minCropBoxHeight: 256,
      minCropBoxWidth: 256,
      viewMode: 3,
      dragMode: 'move',
      crop() {
        // console.log(event.detail.x);
        // console.log(event.detail.y);
        // console.log(event.detail.width);
        // console.log(event.detail.height);
        // console.log(event.detail.rotate);
        // console.log(event.detail.scaleX);
        // console.log(event.detail.scaleY);
      },
    });
  },

  watch: {
    user() {
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.phone = this.user.phone;
      this.email = this.user.email;
      this.address = this.user.address;
    },

    previewUrl() {
      this.cropper.replace(this.previewUrl);
    },
  },

  methods: {
    ...mapActions('auth', ['resetPasswordstatus']),
    // async handleResetPassword() {
    //   this.isLoading = true;
    //   const { currentPassword, newPassword, confirmPassword } = this;

    //   try {
    //     await this.resetPasswordstatus({
    //       currentPassword,
    //       password: newPassword,
    //       confirmPassword,
    //     });
    //     this.isLoading = false;
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    cropImageEvent() {
      this.cropper
        .getCroppedCanvas({
          width: 256,
          height: 256,
        })
        .toBlob((blob) => {
          // console.log(blob);
          const file = new File([blob], 'myImage.jpg', {
            lastModified: 1534584790000,
          });
          // console.log(file);
          // console.log(document.getElementById('profile_pic').files);
          const newData = new DataTransfer();
          newData.items.add(file);
          // console.log(newData.files);

          document.getElementById('profile_pic').files = newData.files;
        }, 'image/jpeg');
    },
    updatePreview() {
      this.isPreview = true;
      const { files } = document.getElementById('profile_pic');
      this.previewUrl = URL.createObjectURL(files[0]);
      // console.log(this.previewUrl);
      // const image = document.getElementById('image');
      // console.log(this.$refs.cropImage);
      // cropper.crop()
    },
    async updateProfile() {
      this.isSubmitLoading = true;
      const profilePayload = {
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
        email: this.email,
        address: this.address,
        apiKey: this.apiKey,
      };

      const fd = new FormData();
      const { files } = document.getElementById('profile_pic');
      // console.log(files[0]);
      if (files[0]) {
        // console.log(files[0]);
        fd.append('profile_upload', files[0]);
      }

      Object.keys(profilePayload).forEach((key) => {
        // console.log(key, profilePayload[key]);
        fd.append(`${key}`, profilePayload[key]);
      });

      await this.$store.dispatch('auth/updateProfile', fd);
      this.isPreview = false;
      this.isSubmitLoading = false;

      const eventPayload = {
        verb: 'PROFILE_UPDATE',
        value: {
          data: fd,
        },
      };
      this.$store.dispatch('eventToEventStore', eventPayload);
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  background: #fff;
  box-shadow: 0px 0px 1px #b8b8b8;
}

#crop__image {
  display: block;

  /* This rule is very important, please don't ignore this */
  max-width: 100%;
}
.profile {
  &__image {
    height: auto;
    width: 100%;

    &-container {
      width: 12.125em;
      height: 12.125em;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}
.error-text {
  color: red;
  margin-top: 0.5rem;
  font-size: 0.875rem;
}
</style>
