<template>
    <div>
        <edModal
            :confirmLoading="confirmLoading"
            :visible="visible"
            @handleOk="modalSearch"
            @cancelModal="modalSearchModal"
        >
        <template #dynamic-content>
          <div>
            <label for="inputQuestion" class="form-label">Question</label>
            <a-input v-model="questionData" ref="questionInput" id="inputQuestion" placeholder="Enter your question..." focus/>
          </div>
        </template>
      </edModal>
    </div>
</template>

<script>
import edModal from '@/common/components/ed-forms/ed-modal.vue';
// eslint-disable-next-line global-require

export default {
  data() {
    return {
      questionData: '',
      confirmLoading: false,
      visible: false,
    };
  },
  components: {
    edModal,
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyCombination);
  },
  methods: {
    handleKeyCombination(event) {
      // Check if 'G' key is pressed along with 'Alt' and 'Shift'
      if (event.altKey && event.shiftKey && event.keyCode === 69) {
        console.log('Alt + Shift + G combination pressed!');
        this.visible = !this.visible;
        if (this.visible) {
          this.$nextTick(() => this.$refs.questionInput.focus());
        }
        // Your logic here...
      }
    },
    modalSearchModal() {
      this.visible = false;
      this.confirmLoading = false;
      this.questionData = '';
    },
    async modalSearch() {
      try {
        this.confirmLoading = true;
        await this.$store.dispatch('gmodule/expandGraphDataUsingVector', this.questionData);
        console.log('updates');
        this.confirmLoading = false;
        this.questionData = '';
        this.visible = false;
      } catch (err) {
        this.modalSearchModal();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
</style>
