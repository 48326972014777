<template>
  <div class="intent-display-drawer">
    <div class="intent-display-drawer__header">
      <h1 class="intent-display-drawer__title">{{ $route.params.searchTerm }}</h1>
      <button class="ed-btn ed-btn__blim" @click="goToLibrary">
        <i class="bx bx-library"></i> Another Aspect
      </button>
    </div>
    <hr/>
    <div class="intent-display-drawer__content" v-html="markedResult"></div>
    <button class="ask-button ed-btn ed-btn__blim" @click="askQuestions">
       Ask Me Questions <i class="bx bx-question-mark"></i>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      test: 'title',
      intentFetchedResult: '',
      correctAnswers: {},
      userMarkedCorrectAnswers: {},
      totalQuestions: 0,
      correctCount: 0,
      showSubmitButton: false,
      showEvaluateButton: false,
      isSubmitDisabled: false,
      isEvaluateDisabled: false,
      isLoading: false,
      isAnswerMode: true,
      searchTerm: null,
      vectorStoreId: null,
      notes: {},
      review: '',
    };
  },

  async mounted() {
    this.notes = this.defaultNotes; // Initialize notes
    // await this.fetchInfoUsingIntents();
    // this.addObserver();
  },

  computed: {
    markedResult() {
      return this.intentFetchedResult.replace(/<p>\n<strong>Answers<\/strong>:([\s\S]*?)<\/p>/, '');
    },
    assistantId() {
      return this.$store.state.eddie.mainAssistantId;
    },
  },

  methods: {
    async fetchInfoUsingIntents() {
      await this.askEddieForResponse(this.$route.params.searchTerm, this.$route.params.intentId, (decodedChunk) => {
        this.intentFetchedResult += decodedChunk;
      });
      this.showSubmitButton = true; // Show the Submit button after streaming ends
    },

    async setCurrentThought(searchTerm, intentId, vectorStoreId = null) {
      let retrieveVectorStoreId;

      if (typeof vectorStoreId === 'string') {
        if (vectorStoreId === 'null') {
          retrieveVectorStoreId = null;
        } else {
          retrieveVectorStoreId = vectorStoreId;
        }
      }

      // console.log('xvf', 'vector Account', retrieveVectorStoreId);
      this.searchTerm = searchTerm;
      if (retrieveVectorStoreId) {
        this.vectorStoreId = retrieveVectorStoreId;
        await this.askPromptPlayground({
          vectorStoreId: retrieveVectorStoreId, intentId, prompt: searchTerm, assistantId: this.assistantId,
        }, (decodedChunk) => {
          this.intentFetchedResult += decodedChunk;
        });
      } else {
        await this.askEddieForResponse(searchTerm, intentId, (decodedChunk) => {
          this.intentFetchedResult += decodedChunk;
        });
      }
    },

    async askEddieForResponse(prompt, intentId, callback) {
      const payload = {
        prompt,
        context: '',
        node_info_section: {
          video: false,
          questions: false,
          article: false,
        },
        prompt_id: intentId,
        stream: true,
      };

      const token = localStorage.getItem('userToken');
      const response = await fetch(`${process.env.VUE_APP_AXIOS_URI}/eddie/prompt/info/stream`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let chunk;
      // eslint-disable-next-line no-cond-assign, no-await-in-loop
      while (!(chunk = await reader.read()).done) {
        const decodedChunk = decoder.decode(chunk.value);
        callback(decodedChunk);
      }
    },

    async askPromptPlayground({
      vectorStoreId, intentId, prompt, assistantId,
    }, cb) {
      await this.$store.dispatch('quests/promptPlayground', {
        vectorStoreId,
        intentId,
        prompt,
        assistantId,
        callback: (chunk) => {
          cb(chunk);
        },
      });
    },

    goToLibrary() {
      this.$router.push({ name: 'WidgetView', params: { name: 'KaaroWidget' }, query: { toggleKaaroWidgetView: '[]', setCurrentThought: `[${this.searchTerm},${this.vectorStoreId}]` } });
    },

    askQuestions() {
      this.$router.push({ name: 'WidgetView', params: { name: 'AskMeQuestionWidget' }, query: { setCurrentThought: `[${this.searchTerm},668241952d18558c8762aa98]` } });
    },
  },
};
</script>

<style lang="scss" scoped>
.intent-display-drawer {
  padding: 2rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  &__title {
    text-transform: capitalize;
    margin: 0;
  }

  &__content {
    padding: 1rem;
  }
}
</style>
