<template>
  <div class="event-container">
    <h1>Users</h1>
    <div class="button-container">
      <div class="input-container">
        <input v-model="username" placeholder="Enter username" class="input-event-wrap" />
        <button @click="applyUserFilter" class="filter-btn" :disabled="isLoading">
          <span v-if="!isLoading"><i class='bx bx-filter-alt'></i></span>
          <span v-else class="spinner-border spinner-border-sm ml-2" :style="{ opacity: isLoading ? 1 : 0 }"></span>
        </button>
      </div>

      <div class="year-btn">
    <!-- <button class="year-button" @click="showPresentYearView">Present Year</button> -->
    <select v-model="selectedYear" @change="showYearView">
        <option v-for="year in yearRange" :key="year" :value="year">{{ year }}</option>
    </select>
</div>
    </div>
    <hr/>
    <div>
      <calendar-heatmap
        :end-date="datee"
        :values="finalDates"
        :style="{ 'max-width': '675px' }"
        :range-color="['#ebedf0', '#9be9a8', '#40c463', '#30a14e', '#216e39']"
        :max="1000"
        :round="2"
      ></calendar-heatmap>
    </div>
  </div>
</template>

<script>
import { CalendarHeatmap } from 'vue-calendar-heatmap';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    CalendarHeatmap,
  },
  computed: {
    ...mapState(['eventsData']),
  },
  data() {
    const currentYear = new Date().getFullYear();
    return {
      dates: [],
      finalDates: [],
      selectedYear: currentYear,
      datee: new Date(),
      username: this.$store.state.auth.user.username,
      isLoading: false,
      yearRange: this.getYearRange(2019, new Date().getFullYear()),
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(['fetchEvents']),

    async showYearView() {
      const currentYear = new Date().getFullYear();
      if (this.selectedYear) {
        if (this.selectedYear === currentYear) {
          this.datee = new Date();
        } else {
          this.datee = new Date(this.selectedYear, 0, 1);
        }
        await this.fetchData(this.username, this.datee);
      }
    },

    // async showPresentYearView() {
    //   this.datee = new Date();
    //   await this.fetchData(this.username, this.datee);
    // },

    async fetchData(username) {
      this.isLoading = true;
      try {
        await this.fetchEvents(username);
        this.processEvents();
      } catch (error) {
        console.log(error.message);
      } finally {
        this.isLoading = false;
      }
    },

    getYearRange(startYear, endYear) {
      const years = [];
      for (let year = startYear; year <= endYear; year++) {
        years.push(year);
      }
      return years;
    },

    applyUserFilter() {
      this.fetchData(this.username);
    },

    processEvents() {
      try {
        this.dates = this.eventsData.map((event) => {
          const dateString = event.timestamp.machineReadable;
          const day = new Date(dateString);
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(day)) {
            throw new Error(`Invalid date: ${dateString}`);
          }
          return day.toISOString().slice(0, 10);
        });

        setTimeout(() => {
          this.filterDates();
        }, 2000);
      } catch (error) {
        console.log('Error converting date:', error.message);
      }
    },

    filterDates() {
      const elementCounts = {};
      this.dates.forEach((element) => {
        elementCounts[element] = (elementCounts[element] || 0) + 1;
      });
      console.log(elementCounts);
      this.finalDates = Object.entries(elementCounts).map(([date, count]) => ({
        date,
        count,
      }));
    },
  },
};
</script>

<style>
.event-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.input-container {
  position: relative;
  display: inline-block;
}

.filter-btn {
  position: absolute;
  top: 50%;
  border: none;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
}

.spinner-border {
  margin-left: 5px;
}

.year-btn {
  margin-left: auto;
}

:root {
  --primary-color: #4caf50;
  --hover-color: #45a049;
  --active-color: #3e8e41;
  --focus-border-color: #555;
  --input-border-color: #ccc;
  --input-focus-border-color: var(--focus-border-color);
}

.container {
  padding: 20px;
}

.title {
  font-size: 2em;
  margin-bottom: 20px;
}

.button-container {
 display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.input-event-wrap {
  padding: 10px;
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
    flex: 1;
    box-sizing: border-box;
}

.input-event-wrap:focus {
  border-color: var(--input-focus-border-color);
  outline: none;
}

.year-button {
  background-color: var(--primary-color);
  border: none;
  color: white;
  padding: 10px 15px;
  margin: 2px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.year-button:hover {
  background-color: var(--hover-color);
}

.year-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

.year-button:active {
  background-color: var(--active-color);
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 0.1em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.heatmap-container {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .event-container {
    width: 100%;
    padding: 10px;
  }

  .button-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .year-btn {
    margin-left: 0;
    margin-top: 10px;
  }
}
</style>
