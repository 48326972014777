<template>
  <div id="game_feedback_box" class="game_feedback_box">
    <h3>Do you have any feedback?</h3>
    <a-form @submit="handleSubmit">
      <a-form-item>
        <a-textarea
          v-model="userFeeback"
          placeholder="Please enter your feedback"
          :rows="4"
        />
      </a-form-item>
      <a-form-item>
        <a-button class="game_feedback_box__btn"
          type="primary"
          html-type="submit">
          Submit Feedback
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>

export default {
  data() {
    return {
      userFeeback: '',
      gameSessionId: '',
    };
  },

  async mounted() {
    window.addEventListener('message', (event) => {
      // Validate the origin
      if (event.origin !== process.env.VUE_APP_GAME_URI) {
        return; // Do not trust messages from unexpected sources
      }

      // Handle the data received from the other document
      this.gameSessionId = event.data;
      console.log('GameSessionId message:', event.data);
    });
  },

  computed: {
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      // Submit the feedback input to your backend or handle it as needed
      // Clear the textarea after submission
      this.$store.dispatch('game/sendGameFeedback', {
        gameSessionId: this.gameSessionId,
        userFeedback: this.userFeeback,
      });
      this.feedbackInput = '';
    },
  },
};
</script>

<style lang="scss" scoped>
#game_intent_box {
    min-height: 90vh;
}
.game_feedback_box {
  padding: 2rem;
  h3 {
    padding-bottom: .5rem !important;
  }
  &_btn {
    padding: 1rem;
    border-radius: .3rem;
  }
}

</style>
