<template>
    <div id="frameBed">
        <component @hook:mounted="componentMounted" :is="$route.params.id" />
    </div>
</template>

<script>
import { coreComponents } from '@/config/componentLoader';
import GraphFrame from '@/common/components/frameComp/GraphFrame.vue';
import QuestFrame from '@/common/components/frameComp/QuestFrame.vue';
import GameFrame from '@/common/components/frameComp/GameFrame.vue';

export default {
  components: {
    ...coreComponents,
    GraphFrame,
    GameFrame,
    QuestFrame,
  },

  data() {
    return {
      search: '',
      componentId: null,
      payload: null,
      frameDetail: {
        WikiDataCardWidget: {
          payload: (word) => ({
            thoughtObject: {
              word,
              label: word,
              lang: 'en',
              type: 'string',
            },
          }),
        },
        VideosWidget: {
          payload: (word) => ({
            thoughtObject: {
              word,
              label: word,
              lang: 'en',
              type: 'string',
            },
          }),
        },
        GraphExploreWidget: {
          payload: (graphId) => ({
            thoughtObject: {
              graphId,
              type: 'graph',
            },
          }),
        },
        DendroGraphWidget: {
          payload: (graphId) => ({
            thoughtObject: {
              graphId,
              type: 'graph',
            },
          }),
        },
        GraphFrame: {
          payload: (graphId) => ({
            thoughtObject: {
              graphId,
              type: 'graph',
            },
          }),
        },
        QuestFrame: {
          payload: (graphId) => ({
            thoughtObject: {
              questLibrary: true,
              graphId,
              type: 'graph',
            },
          }),
        },
        GameFrame: {
          payload: (word) => ({
            thoughtObject: {
              word,
              label: word,
              lang: 'en',
              type: 'string',
            },
          }),
        },
      },
    };
  },

  methods: {
    async componentMounted() {
      await this.$store.dispatch('setCurrentThought', this.payload);
    },
  },
  async created() {
    this.componentId = this.$route.params.id;
    this.payload = this.frameDetail[this.componentId].payload(this.$route.params.search);
  },
};
</script>

<style lang="scss" scoped>
#frameBed {
    width: 100%;
    height: 100%;
    // min-height: 100vh;
}
</style>
